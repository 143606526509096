import { Component, OnInit, Input, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material';
import { LanguageService } from '../../providers/language.service';

@Component({
  selector: 'app-sidenav-help',
  templateUrl: './sidenav-help.component.html',
  styleUrls: ['./sidenav-help.component.css']
})
export class SidenavHelpComponent implements OnInit {

  @Input('menuIndex')
  menuIndex;

  public userData;
  public typeAbbonamento;

  public openMenu = false;

  constructor(public languageService: LanguageService, public snackBar: MatSnackBar, private router: Router,) {

  }

   ngOnInit() {
    
  }

  goToLink(link){
    let rout = this.router;
    if(this.typeAbbonamento!=0){
      rout.navigate(['/'+link]);
    }else{
      this.snackBar.openFromComponent(MessSidenavuserComponent, {
        duration: 4500,
        data:{
          message: 'Funzionalità in abbonamento. Per proseguire scegli un piano.',
        }
      });
      rout.navigate(['/subscribe']);
    }
  }

  goToLinkPlatinum(link){
    let rout = this.router;
    if(this.typeAbbonamento==2){
      rout.navigate(['/'+link]);
    }else{
      this.snackBar.openFromComponent(MessSidenavuserComponent, {
        duration: 4500,
        data:{
          message: 'Funzionalità in abbonamento. Per proseguire abbonati al piano Platinum.',
        }
      });
      rout.navigate(['/subscribe-payment/platinum']);
    }
  }

  setMenuOpen(){
    this.openMenu=true;
    console.log(this.openMenu);
  }

  setMenuClose(){
    this.openMenu=false;
    console.log(this.openMenu);
  }

  goToOpen(){
    window.open('https://www.openpost.it');
  }
}


@Component({
  templateUrl: 'message.html',
})
export class MessSidenavuserComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {

  }
}