import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


//PROVIDER
import { LanguageService } from './providers/language.service';

//MODULES
import { HeaderComponent } from './modules/header/header.component';
import { SidenavHelpComponent } from './modules/sidenav-help/sidenav-help.component';

//PAGES
import { HomeComponent } from './pages/home/home.component';
import { TermsComponent } from './pages/terms/terms.component';
import { VersionComponent } from './pages/version/version.component';
import { ContactComponent } from './pages/contact/contact.component';
import { IntroComponent } from './pages/intro/intro.component';

//ANGULAR MATERIAL
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBarModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatTooltipModule, MatRadioModule, MatProgressSpinnerModule, MatToolbarModule, MatMenuModule, MatSidenavModule, MatButtonModule, MatIconModule, MatGridListModule, MatCardModule, MatTabsModule, MatDialogModule, MatOptionModule, MatFormFieldModule, MatSelectModule, MatInputModule } from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatPaginatorModule, MatSortModule, MatTableModule } from "@angular/material";

//FIREBASE
import { AngularFireModule } from '@angular/fire';


export const firebaseConfig = {
  apiKey: "AIzaSyDISqZjIoUgmyliVgP4EcmjHLVUyHJexNI",
  authDomain: "openposthelp.firebaseapp.com",
  databaseURL: "https://openposthelp.firebaseio.com",
  projectId: "openposthelp",
  storageBucket: "openposthelp.appspot.com",
  messagingSenderId: "55168822611",
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavHelpComponent,
    HomeComponent,
    TermsComponent,
    VersionComponent,
    ContactComponent,
    IntroComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatToolbarModule, 
    MatSidenavModule, 
    MatButtonModule, 
    MatIconModule,
    MatSnackBarModule,
    AngularFireModule.initializeApp(firebaseConfig),
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    LanguageService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
