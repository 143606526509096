import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  public type;
  public menuIndex;

  constructor(private route: ActivatedRoute,) {
    this.route.params.subscribe((params: ParamMap) => {
      this.type = params['type']; 
    });
   }

  ngOnInit() {
  }

}
