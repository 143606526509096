import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../providers/language.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  menuIndex=4;

  public label;
  public language = 'it';

  public openMenu :boolean; //variabile che stabilisce se il menù è chiuso o aperto 

  constructor(public languageService: LanguageService) {
    this.label = this.languageService.getLanguage();
  }

  ngOnInit() {
  }

}
