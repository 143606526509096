import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../providers/language.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  menuIndex=1;

  public label;
  public language = 'it';
  public vers = 'desktop';

  public openMenu :boolean; //variabile che stabilisce se il menù è chiuso o aperto 

  constructor(public languageService: LanguageService) {
    this.label = this.languageService.getLanguage();
  }

  ngOnInit() {
  }

}
