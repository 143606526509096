import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageService {

    public language = 'it';
    public labelLanguage = {
        home:{
            openpost: {
                it: 'Openpost',
                en: '',
            },
            attivita:{
                it: 'Attività',
                en : '',
            },
        },
        content_preview:{
            aggiorna: {
                it: 'Aggiorna',
                en: '',
            },
            cerca_per_paese:{
                it: 'Cerca per paese',
                en : '',
            },
            openpost_aggiornati:{
                it: 'Openpost aggiornati',
                en : '',
            },
            paese_non_trovato:{
                it: 'Paese non trovato',
                en : '',
            },
            ricerca:{
                it: 'Ricerca',
                en : '',
            },
            non_ci_sono:{
                it: 'Non ci sono altri Openpost',
                en : '',
            }
        },
        website_preview:{
            aggiorna: {
                it: 'Aggiorna',
                en: '',
            },
            cerca_per_paese:{
                it: 'Cerca per paese',
                en : '',
            },
            attivita_aggiornate:{
                it: 'Attività aggiornate',
                en : '',
            },
            paese_non_trovato:{
                it: 'Paese non trovato',
                en : '',
            },
            ricerca:{
                it: 'Ricerca',
                en : '',
            },
            non_ci_sono:{
                it: 'Non ci sono altre Attività',
                en : '',
            },
            visita:{
                it: 'VISITA',
                en : '',
            }
        }
    }

    constructor() {

    }

    //recupera tutti i contenuti dell'utente in base allo stato del contenuto
    getLanguage() {
        return this.labelLanguage;
    }
}
