import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import { LanguageService } from '../../providers/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  @Input('searchIndex')
  searchIndex;

  @Input('controllerIndex')
  controllerIndex;

  public openMenu : boolean;


  constructor(private languageService: LanguageService) { 
  }

  ngOnInit() {
    
  }
}