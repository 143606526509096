import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  menuIndex=3;

  public label;
  public language = 'it';

  constructor() { }

  ngOnInit() {
  }

}
